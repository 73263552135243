import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'
import { ClerkApp, ClerkErrorBoundary, useUser } from '@clerk/remix'
import { rootAuthLoader } from '@clerk/remix/ssr.server'
import { cssBundleHref } from '@remix-run/css-bundle'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError,
} from '@remix-run/react'
import { Analytics } from '@vercel/analytics/react'
import type { LinksFunction, LoaderFunctionArgs } from '@vercel/remix'
import { json } from '@vercel/remix'
import { SpeedInsights } from '@vercel/speed-insights/remix'
import { useEffect } from 'react'
import { Button } from './components/ui/button'
import { DOMAIN_URL_PUBLIC } from './lib/core/constants.server'
import stylesheet from './tailwind.css?url'

// websites for generating these icons:
// - https://favicon.io/favicon-converter/
// - https://iconifier.net/
export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: stylesheet },
  ...(cssBundleHref ? [{ rel: 'stylesheet', href: cssBundleHref }] : []),
  { rel: 'apple-touch-icon', href: '/apple-touch-icon.png' },
  { rel: 'apple-touch-icon-precomposed', href: '/apple-touch-icon-precomposed.png' },
  { rel: 'apple-touch-icon', sizes: '57x57', href: '/apple-touch-icon-57x57.png' },
  { rel: 'apple-touch-icon', sizes: '72x72', href: '/apple-touch-icon-72x72.png' },
  { rel: 'apple-touch-icon', sizes: '76x76', href: '/apple-touch-icon-76x76.png' },
  { rel: 'apple-touch-icon', sizes: '114x114', href: '/apple-touch-icon-114x114.png' },
  { rel: 'apple-touch-icon', sizes: '120x120', href: '/apple-touch-icon-120x120.png' },
  {
    rel: 'apple-touch-icon-120x120-precomposed',
    sizes: '120x120',
    href: '/apple-touch-icon-120x120-precomposed.png',
  },
  { rel: 'apple-touch-icon', sizes: '144x144', href: '/apple-touch-icon-144x144.png' },
  { rel: 'apple-touch-icon', sizes: '152x152', href: '/apple-touch-icon-152x152.png' },
  { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon-180x180.png' },
  { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
  { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
  { rel: 'manifest', href: '/site.webmanifest' },
]

export function loader(args: LoaderFunctionArgs) {
  // If you need to load in additional data, you can pass your loader directly to the rootAuthLoader
  return rootAuthLoader(args, () => {
    return json({
      // set a global variable that our client-side code can read
      // you need to update global.d.ts as well
      ENV: {
        DOMAIN_URL_PUBLIC: DOMAIN_URL_PUBLIC,
      },
    })
  })
}

function AppRoot() {
  const data = useLoaderData<typeof loader>()
  const { user } = useUser()

  useEffect(() => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.innerHTML = `
      window.$crisp=[];
      window.CRISP_WEBSITE_ID="480e405b-8817-48ae-a635-c8779eae64af";
      (function(){
        d=document;
        s=d.createElement("script");
        s.src="https://client.crisp.chat/l.js";
        s.async=1;
        d.getElementsByTagName("head")[0].appendChild(s);
      })();
    `
    document.body.appendChild(script)
  }, [])

  useEffect(() => {
    if (user) {
      window.$crisp.push(['set', 'user:email', [user.primaryEmailAddress?.emailAddress]])
      if (user.fullName) {
        window.$crisp.push(['set', 'user:nickname', [user.fullName]])
      }
      window.$crisp.push(['set', 'user:avatar', [user.imageUrl]])
    }
  }, [user])

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        {/* Browser Environments. This needs to be done before <Scripts/> */}
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
          }}
        />
        <Scripts />
        <Analytics />
        {process.env.NODE_ENV === 'production' && <SpeedInsights />}
      </body>
    </html>
  )
}

function InnerErrorBoundary() {
  const error = useRouteError()
  console.error(error)

  let body
  if (isRouteErrorResponse(error)) {
    body = (
      <div className="flex flex-col items-center justify-center p-16">
        <h1 className="text-3xl">
          {error.status} {error.statusText}
        </h1>
        <p>{error.data}</p>
        <p className="mt-4">
          <Button variant="link" className="text-xl" asChild>
            <a href={'/'}>Return Home</a>
          </Button>
        </p>
      </div>
    )
  } else if (error instanceof Error) {
    body = (
      <div className="flex flex-col items-center justify-center p-16">
        <h1 className="text-3xl">Oh no! Something went wrong!</h1>
        <p>{error.message}</p>
        {error.stack && (
          <div className="text-left">
            <p>The stack trace is:</p>
            <pre>{error.stack}</pre>
          </div>
        )}
        <p>ICT Timestamp: {new Date(Date.now() + 7 * 60 * 60 * 1000).toUTCString()}</p>
        <p className="mt-4">
          <Button variant="link" className="text-xl" asChild>
            <a href={'/'}>Return Home</a>
          </Button>
        </p>
      </div>
    )
  } else {
    body = <h1>Unknown Error</h1>
  }

  return (
    <html>
      <head>
        <title>Oh no!</title>
        <Meta />
        <Links />
      </head>
      <body>
        {body}
        <Scripts />
      </body>
    </html>
  )
}

export default ClerkApp(AppRoot, { appearance: { variables: { colorPrimary: '#E11D48' } } })
export const ErrorBoundary = ClerkErrorBoundary(InnerErrorBoundary)
